import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import * as CookieConsent from 'vanilla-cookieconsent';

function Footer() {
  const { role } = useSelector((state) => state.mainFun.user);
  const { t } = useTranslation();

  return (
    <footer className="footer d-flex flex-column gap-3 py-3 align-items-end">
      <section className="d-flex w-100 justify-content-between">
        <p className="text-capitalize">
          {t('powered_by')}{' '}
          <a href="https://plusone.expert" target="_blank" className="green-txt text-decoration-underline">
            PlusOne health
          </a>{' '}
          GmbH
        </p>
        {role && (
          <div className="green-text d-flex">
            <Link to={`${role}/termsofuse`} className="green-txt text-capitalize">
              {t('terms_use')}
            </Link>
            <Link to={`${role}/privacypolicy`} className="green-txt ms-2 ms-sm-5 text-capitalize">
              {t('privacy_policy')}
            </Link>
            {localStorage.token && (
              <p onClick={() => CookieConsent.showPreferences()} className="green-txt ms-2 ms-sm-5 pointer">
                {t('cookies_pref')}
              </p>
            )}
          </div>
        )}
      </section>
      <p>
        <strong>Support:</strong>{' '}
        <a className="green-txt" href={`mailto:${import.meta.env.VITE_SUPPORT_EMAIL}`}>
          {import.meta.env.VITE_SUPPORT_EMAIL}
        </a>
      </p>
    </footer>
  );
}

export default Footer;
