import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { validateSchema } from './validate_schema.js';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

const Validation = ({ type, defaultValues, customField, shouldUnregister }) => {
  const { t } = useTranslation();

  const [formSchema, setFormSchema] = useState({});

  const defaultFormSchema = {
    register: {
      name: validateSchema['name'],
      email: validateSchema['email'],
      password: validateSchema['password'],
      'confirm password': validateSchema['confirmPassword']
    },
    registerPatient: {
      first_name: validateSchema['first_name'],
      last_name: validateSchema['last_name'],
      email: validateSchema['email'],
      'confirm email': validateSchema['confirmEmail'],
      password: validateSchema['password'],
      'confirm password': validateSchema['confirmPassword'],
      policyaccept: validateSchema['checkBox_required'],
      recaptchatoken: validateSchema['recaptchatoken'],
      newsletter_updates: validateSchema['newsletter'],
      residance: validateSchema['residance']
    },
    login: {
      email: validateSchema['loginEmail'],
      password: validateSchema['loginPassword']
    },
    'forgot password': {
      email: validateSchema['email']
    },
    'reset password': {
      password: validateSchema['password'],
      'confirm password': validateSchema['confirmPassword']
    },
    'change password': {
      'old password': validateSchema['oldPassword'],
      password: validateSchema['password'],
      'confirm password': validateSchema['confirmPassword']
    },
    'missing request': {
      missingRequest: validateSchema['missingRequest']
    },
    service: {
      complaint: validateSchema['complaint'],
      question: validateSchema['question_optional'],
      // speciality_id: validateSchema['speciality']
    },
    demographics: {
      mobile: validateSchema['mobile'],
      date_of_birth: validateSchema['date_of_birth'],
      country: validateSchema['country'],
      gender: validateSchema['gender'],
      blood_type: validateSchema['blood_type'],
      country_id: validateSchema['country_id'],
      weight: validateSchema['weight'],
      height: validateSchema['height'],
      address: validateSchema['address'],
      city: validateSchema['country'],
      zip: validateSchema['gender']
    },
    profile: {
      first_name: validateSchema['first_name'],
      last_name: validateSchema['last_name'],
      email: validateSchema['email']
    },
    questions: {}
  };

  const schema = yup.object().shape(formSchema[type]);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    control,
    getValues,
    setValue,
    unregister,
    reset,
    setError
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: defaultValues || {},
    shouldUnregister
  });

  let translatedErrors = {};
  if (errors) {
    console.log(errors);
    Object.entries(errors).forEach(([key, value]) => {
      translatedErrors[key] = { ...value, message: t(value.message) };
    });
  }

  useEffect(() => {
    const newSchema = defaultFormSchema;
    if (customField?.length > 0) {
      customField.forEach((e) => {
        newSchema[type][e.name] = validateSchema[e.validate];
      });
    }
    setFormSchema(newSchema);
  }, [customField]);

  useEffect(() => {
    if (customField) reset([], { keepValues: true });
  }, [formSchema]);

  return {
    register,
    handleSubmit,
    errors: translatedErrors,
    control,
    setValue,
    unregister,
    setError,
    isValid
  };
};

export default Validation;
